import { device } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: #161616;

  h2 + p {
    font-family: 'Sora';
  }

  .text-card {
    background-color: rgba(0, 0, 0, 0.7);
    height: 420px;
    width: 100%;
    position: absolute;

    @media ${device.tablet} {
      height: 393px;
    }

    @media ${device.desktopLG} {
      height: 462px;
    }

    @media ${device.desktopXL} {
      height: 560px;
    }
  }

  /* a {
    @media ${device.tablet} {
      width: 324px;
    }

    @media ${device.desktopLG} {
      width: 377px;
    }

    @media ${device.desktopXL} {
      width: 324px;
    }
  } */

  .react-multi-carousel-dot {
    position: relative;
    top: -3px;
  }

  .react-multiple-carousel__arrow {
    svg {
      fill: white !important;
      position: relative;
    }
  }


  .react-multiple-carousel__arrow--right {
    right: 179px!important;

    @media ${device.tablet} {
      right: 249px!important
    }

    @media ${device.desktopLG} {
      right: 289px!important
    }

    @media ${device.desktopXL} {
      right: 369px!important
    }

    @media ${device.desktopXXXL} {
      right: 490px!important
    }
  }

  .react-multiple-carousel__arrow--left {
    left: 179px!important;

    @media ${device.tablet} {
      left: 249px!important
    }

    @media ${device.desktopLG} {
      left: 289px!important
    }

    @media ${device.desktopXL} {
      left: 369px!important
    }

    @media ${device.desktopXXXL} {
      left: 490px!important
    }
  }

  .react-multi-carousel-dot--active button {
    background: ${white} !important;
    opacity: 1 !important;
  }
`

export const Circle = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 30px;
  position: absolute;
  margin-top: 15px;
  margin-left: calc(100% - 52px);
  z-index: 3;

  @media ${device.tablet} {
    width: 34px;
    height: 34px;
  }

  @media ${device.desktopLG} {
    width: 40px;
    height: 40px;
  }

  @keyframes loading {
    0% { transform: rotate(0); }
    100% { transform: rotate(-90deg); }
  }

  @keyframes reverseloading {
    0% { transform: rotate(0); }
    100% { transform: rotate(90deg); }
  }

  &.open {
    background-color: #FF7A00;
    animation: loading .3s;
  }

  &.close {
    background-color: #000000;
    animation: reverseloading .3s;
  }
}
`

export const Card = styled.div`
  height: 420px;
  position: relative;
  background-size: 430px;
  background-repeat: no-repeat;
  cursor: pointer;
  background-position: top center;
  transition: .4s;

  @media ${device.tablet} {
    height: 393px;
    background-size: 295px;
  }

  @media ${device.desktopLG} {
    height: 462px;
    background-size: 462px;
  }

  @media ${device.desktopXL} {
    height: 560px;
    background-size: 480px;
  }

  @media ${device.desktopXXXL} {
    background-size: 100px;
    width: 420px;
  }

  @keyframes zoom {
    0% {
      background-size: 420px;
    }
    100% {
      background-size: 588px;
    }
  }

  @keyframes reversezoom {
    0% {
      background-size: 588px;
    }
    100% {
      background-size: 420px;
    }
  }

  &.bg {
    animation: zoom .4s ease-in-out forwards;

    &-reverse {
      animation: reversezoom .4s ease-in-out forwards;
    }
  }

  .detail {
    @media ${device.tablet} {
      position: absolute;
      bottom: 33px;
      z-index: 4;
      left: 16px;
    }

    @media ${device.desktopXL} {
      left: 24px;
    }
  }
`

export const ContentText = styled.div`
  position: absolute;
  margin-left: 30px;
  padding: 0 20px 0 0;
  margin-top: 300px;

  p, h3 {
    font-family: 'Sora';
  }

  @media ${device.tablet} {
    margin-left: 16px;
  }

  @media ${device.desktopLG} {
    margin-top: 350px;
  }

  @media ${device.desktopXL} {
    margin-left: 24px;
    margin-top: 430px;
  }

  &.top {
    margin-top: 120px;

    @media ${device.tablet} {
      margin-top: 80px;
    }

    @media ${device.desktopLG} {
      margin-top: 100px;
    }
  }

  .title {
    font-size: 30px;
    line-height: 37px;
    font-weight: 600;
    color: ${white};
    z-index: 2;
    position: relative;

    @media ${device.tablet} {
      font-size: 20px;
      line-height: 25px;
    }

    @media ${device.desktopLG} {
      font-size: 23px;
      line-height: 29px;
    }

    @media ${device.desktopXL} {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .descritpion {
    margin-top: 34px;
    font-size: 14px;
    line-height: 22px;
    color: ${white};
    z-index: 2;
    position: relative;
    height: 140px;

    @media ${device.tablet} {
      margin-top: 14px;
      font-size: 10px;
      line-height: 20px;
    }

    @media ${device.desktopLG} {
      font-size: 12px;
      line-height: 23px;
    }

    @media ${device.desktopXL} {
      font-size: 16px;
      line-height: 32px;
    }
  }
`

export const Button = styled.button`
  @media ${device.tablet} {
    width: 324px;
  }

  @media ${device.desktopLG} {
    width: 377px !important;
  }

  @media ${device.desktopXL} {
    width: 324px;
  }

  @media ${device.desktopLG} {
    width: 37%;
  }
`

export const ButtonMobile = styled.button`
  width: 312px;
  margin: 0 auto;
`
